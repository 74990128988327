import { routes } from "constants/routes";
import { useSession } from "providers/SessionProvider";
import { Navigate, Outlet } from "react-router-dom";

const BalanceGuard = () => {
    const { user } = useSession();

    return (
        user.is_charged
            ? <Outlet /> : <Navigate to={routes.profile} />
           
    );
}

export default BalanceGuard;
